import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchDatas(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .get('module/finance_payables', {
            params: userData
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchData(ctx, {
      id
    }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`module/finance_payables/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addData(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('module/finance_payables', userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editData(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`module/finance_payables/${userData.id}`,
            userData
          )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteData(ctx, {
      id
    }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/module/finance_payables/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    downloadDocument(ctx, {
      id
    }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`v1/finance_payables/download/${id}`, {
            responseType: 'blob'
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteDocument(ctx, {
      id
    }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`finance_payables/filedocument/${id}`, {
            responseType: 'blob'
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    deletefileData(ctx, {
      id
    }) {
      return new Promise((resolve, reject) => {
        axios
          .delete('/finance_payables/filedocument/+${id}')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },


    fetchefamilymemberdetails(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/familymembers/relateddata')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // fetchestate(ctx, queryParams) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .get('/state/getdata')
    //       .then(response => resolve(response))
    //       .catch(error => reject(error))
    //   })
    // },
  },
}
